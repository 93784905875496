@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("../../public/assets/fonts/Ubuntu-Regular.ttf");
  font-display:block;
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("../../public/assets/fonts/Ubuntu-Light.ttf");
  font-display:block;
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: url("../../public/assets/fonts/Ubuntu-Medium.ttf");
  font-display:block;
}

@font-face {
  font-family: "Montserrat-Bold";
  src:url("../../public/assets/fonts/Montserrat-Bold.ttf");
  font-display:block;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/assets/fonts/Montserrat-Regular.ttf");
  font-display:block;
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: local("Manrope-SemiBold"),
    url("../../public/assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-display:block;
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url("../../public/assets/fonts/Inter-Light.ttf") format("truetype");
  font-display:block;
}

@font-face {
  font-family: "Acumin-Pro";
  src: local("Acumin-Pro"),
    url("../../public/assets/fonts/Acumin-Pro.ttf") format("truetype");
  font-display:block;
}

body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: "Ubuntu-Light"; 
}

/* html {
  scroll-behavior: smooth;
} */

::webkit-scrollbar { display: none }