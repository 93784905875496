@use '../../../styles/base/settings' as *;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 5rem;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#009887 0%, #00DFD2 100%);
  border-radius: 5rem;
  position: relative;
  padding-top: 1.45rem;
}

.titleContainer{
  text-align: center;
  color: #FFFFFF;
  font-size: 4.8rem;
  height:85px;
  font-family: "Ubuntu-Regular";
  font-weight: 700;
}

.mainContainer{
  height: 100%;
  width: 100%;
}

.lottiePlayer{
  width: 520px;
  height: 290px;
  border-radius: 40px;
  position: absolute;
  right: 61px;
  top: 14rem;
  z-index: 1;
}

.backgroundImageContainer{
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  position: absolute;
  bottom: 0rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
  @media (max-width: $breakpoint-tablet) {
  .container {
    margin-top: 0.5rem;
  }

  .contentContainer{
    border-radius: 20px;
    padding-top: 28px;
    background-image: linear-gradient(180deg, #62D1C2 0%, #1DDDC3 47.95%, rgba(3, 156, 135, 0.86) 100%);
  }

  .titleContainer{
  text-align: start;
  font-size: 34px;
  height:88px;
  width: 310px;
  margin: auto;
  line-height: 36.1px;
  }

  .textContainer{
    font-size: 46px;
    line-height: 48.71px;
  }

  .lottiePlayer{
    width: 260px;
    height: 145px;
    border-radius: 20px;
    position: static;
    margin: auto;
    margin-top: 25px;
    filter: drop-shadow(0px 38px 80px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.15605px 24.5455px rgba(0, 0, 0, 0.084206));
  }

  .backgroundImageContainer{
    margin-top: 4.25rem;
    height: auto;
    border-radius: 20px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .container {
    margin-top: 0px;
  }

  .contentContainer{
  background-image: linear-gradient(#009887 0%, #00DFD2 100%);
  border-radius: 47.884px;
  padding-top: 20px;
  }

  .titleContainer{
    text-align: center;
    color: #FFFFFF;
    font-size: 41px;
    height:auto;
    width: 100%;
    margin: auto;
    line-height: 43.49px;
  }

  .textContainer{
    font-size: 41px;
  }

  .lottiePlayer{
    width: 300px;
  height: 168px;
  border-radius: 23.07px;
  position: absolute;
  right: 51px;
  top: 98px;
  z-index: 1;
  background: linear-gradient(107.28deg, #FFB800 0%, #F15701 86.82%);
  box-shadow: 0px 0px 11.5385px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5.76923px);
  }

  //TODO:: resize background image
  .backgroundImageContainer{
  overflow: hidden;
  width: 100%;
  height: 410px;
  border-radius: 47.884px;
  position: absolute;
  bottom: 0rem;
  }

  .backgroundImage{
    position: absolute;
    bottom: 0rem;
    margin-top: 1rem;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) {
  .container {
    margin-top: -2rem;
  }
  
  .contentContainer{
    padding-top: 0rem;
    background-color: blue;
  }

.titleContainer{
  font-size: 3.15rem;
  height:auto;
  padding-top: 1.8rem;
}

.lottiePlayer{
  width: 375px;
  height: 275px;
  right: 61px;
  top: 10rem;
}

.backgroundImageContainer{
  height: auto;
}
}

//
@media (max-width: 360px){
  .contentContainer{
    border-radius: 20px;
    padding-top: 25px;
  }

  .titleContainer{
  text-align: start;
  font-size: 25px;
  height:auto;
  width: 215px;
  margin: auto;
  line-height: 21px;
  padding: 0px 5px;
  overflow: hidden;
  }

  .textContainer{
    font-size: 33px;
    line-height: 48.71px;
  }

  .lottiePlayer{
    width: 215px;
    height: 145px;
    margin-top: 20px;
  }

  .backgroundImageContainer{
    margin-top: 4.25rem;
    height: auto;
    border-radius: 20px;
  }
}

@media (min-width: 400px) and (max-width: 545px) {
  .contentContainer{
    padding-top: 35px;
  }
  .lottiePlayer{
    margin-top: 35px;
  }
}

@media (min-width: 545px) and (max-width: 605px) {
  .contentContainer{
    padding-top: 45px;
  }
  .lottiePlayer{
    width: 265px;
    height: 185px;
    margin-top: 65px;
  }
}