@use '../../styles/base/settings' as *;

.bottomContainer{
  margin-top: 30px;
  @include columnDiv;
  align-items: center;
  gap: 16px;
  background-color: #000000;
  padding: 20px 2px;
}

.bottomText1{
  width: 305px;
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FF8431;
}

.bottomText2{
  font-family: 'Ubuntu-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}

.bottomText3{
  color: #4BC7B6;
}

.socialIconsContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap:16px;
  width: 100%;
}

@mixin bgSocialImage {
  background-repeat: no-repeat;
  background-size: contain;
  width: 150px;
  height: 50px;
}

.whatsAppBgImage{
  background-image: url('../../../public/assets/images/whatsAppBtnSmall.svg');
  @include bgSocialImage;
}

.instaBgImage{
  background-image: url('../../../public/assets/images/instaBtnSmall.svg');
  @include bgSocialImage;
}

.playStoreBtnImage{
  background-image: url('../../../public/assets/logos/playStoreBtnSm.svg');
  @include bgSocialImage;
}

.appStoreBtnImage{
  background-image: url('../../../public/assets/logos/appStoreBtnSm.svg');
  @include bgSocialImage;
}

//tablet :: >= 768px
@media (min-width: $breakpoint-tablet){
  .bottomContainer{
    display: none;
  }
}

//laptop  :: >= 1024px
@media (min-width: $breakpoint-laptop) {
  .bottomContainer{
    display: none;
  }
}

//mobile :: < 360px
 @media (max-width: 360px) {
  .socialIconsContainer{
    gap: 8px;
  }

  @mixin bgSocialImage {
    width: 135px;
    height: 45px;
  }

  .bottomText1{
    width: auto;
  }
  
  .bottomText2{
    text-align: center;
  }
  
  .bottomText3{
    color: #4BC7B6;
  }
}
