@use '../../../styles/base/settings' as *;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  height: 100%;
  width: 100%;
  border-radius: 5rem;
  position: relative;
}

.titleContainer{
  text-align: start;
  font-size: 5rem;
  height:21.5rem;
  width: 37.5rem;
  margin-left: 10rem;
  margin-top: 9.813rem;
  padding-right: 3rem;
  line-height: 4.65rem;
  color: #01D3C6;
  font-family: "Ubuntu-Light";
  font-weight: 800;
  overflow: hidden;
}

.titleText1{
  width: 29rem;
  line-height: 5rem;
}

.titleText2{
  font-size: 10rem;
  width: 30rem;
  color: #FF8D1F;
  font-family: "Ubuntu-Medium";
  line-height: 11.5rem;
}

// .lottiePlayer{
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   right: 5.75rem;
//   top: -6.75rem;
//   z-index: 1;
//   max-width: 585.92px;
//   max-height: 696.01px;
//   object-fit: contain;
//   overflow: hidden;
//   transform: scale(1.38);
//   display: flex;
//   justify-content: center;
// }

.bgImage{
  width: 100%;
  height: 100%;
  position: absolute;
  right: 3.85rem;
  top: -7.95rem;
  z-index: 1;
  max-width: 600px;
  max-height: 650px;
  object-fit: contain;
  overflow: hidden;
  transform: scale(0.9);
}

.section1FDesign{
  position: absolute;
  top: -1rem;
  left: 0rem;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  z-index: -1;
}

.bodyContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap:4rem;
  margin-left: 10rem;
  width: 30.875rem;
  line-height: 25px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap:1rem;
  color: #01BBAC;
  font-family: "Ubuntu-Light";
  width: 30rem;
  font-size: 1.5rem;
}

.bodyText2{
  font-size: 1.65rem;
  color: #EE8C34;
  font-family: "Ubuntu-Medium";
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
  @media (max-width: $breakpoint-tablet) {
  .contentContainer{
    border-radius: 0rem;
    padding-top: 50px;
  }

  .titleContainer{
    text-align: center;
    font-size: 1.9rem;
    height:128px;
    width: 205px;
    margin:auto;
    padding: 0rem;
    line-height: 2.6rem;
  }

.titleText1{
  width: 100%;
  line-height: 31.08px;
}

.titleText2{
  font-size: 58.15px;
  width: 100%;
  line-height: 65.81px;
}

//  .lottiePlayer{
//   width: 100%;
//   position: static;
//   margin: auto;
//   margin-top: 9.85rem;
// }

.bgImage{
  width: 100%;
  height: 100%;
  max-width: 362px;
  max-height: 343px;
  object-fit: contain;
  position: static;
  z-index: 1;
  overflow: hidden;
  transform: scale(1.2);
  margin: auto;
  margin-top: 2.1rem;
}

.section1FDesign{
  top: 18rem;
}

  .bodyContainer{
    margin: 0rem;
    width: 100%;
    gap:0rem;
    margin-top: 2rem;
    justify-content: flex-start;
    align-items: center;
  }

  .textContainer{
    width: 319px;
    height: 54px;
    font-size: 1rem;
    text-align: center;
  }

  .bodyText2{
    font-size: 1.15rem;
  }  
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .contentContainer{
    border-radius: 0rem;
    padding-top: 50px;
  }

  .titleContainer{
    text-align: center;
    font-size: 40px;
    height:auto;
    width: auto;
    margin:auto;
    padding: 0rem;
    line-height: 45.96px;
  }

  .titleText1{
    width: 100%;
    line-height: 45.96px;
  }
  
  .titleText2{
    font-size: 70px;
    width: 100%;
    line-height: 80.43px;
  }

    // .lottiePlayer{
    //   width: 515px;
    //   position: static;
    //   margin: auto;
    //   margin-top: 1rem;
    // }

    .bgImage{
      position: static;
      max-width: 416px;
      max-height: 454px;
      transform: scale(0.75);
      margin: auto;
      margin-top: -1.2rem;
    }

    .section1FDesign{
      top: 14rem;
    }

    .bodyContainer{
      justify-content: flex-start;
      align-items: center;
      margin: 0rem;
      width: 100%;
      line-height: 25px;
      gap:0rem;
      margin-top: -28px;
    }
    
    .textContainer{
      width: 416px;
      height: 54px;
      font-size: 1.08rem;
      text-align: center;
    }
    
    .bodyText2{
      font-size: 1.125rem;
    }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 420px) and (max-width: 766px) {
  .bgImage{
    max-width: 362px;
    max-height: 385px;
    transform: scale(0.85);
    margin: auto;
    margin-top: 0rem;
  }
  .bodyContainer{
    margin-top: 0.55rem;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) {
  .titleContainer{
    text-align: start;
    font-size: 3rem;
    height: auto;
    width: 22.5rem;
    margin-left: 8rem;
    margin-top: 8.85rem;
    padding-right: 3rem;
    line-height: 2.65rem;
  }
  
  .titleText1{
    width: 100%;
    line-height: 4rem;
  }
  
  .titleText2{
    font-size: 7.5rem;
    width: 100%;
    line-height: 8.5rem;
  }

  .bgImage{
    right: 0.25rem;
    top: -7.95rem;
    max-width: 585px;
    max-height: 625px;
    transform: scale(0.72);
  }

  // .section1FDesign{
  //   top: -1rem;
  //   left: 0rem;
  // }
  
  .bodyContainer{
    margin-top: 0.55rem;
    margin-left: 8rem;
    width: 22.5rem;
    line-height: 25px;
  }
  
  .textContainer{
    width: 100%;
    font-size: 1.15rem;
  }
  
  .bodyText2{
    font-size: 1.18rem;
  }
}

  //tablet :: < 768px
@media (max-width: 360px) {
  .contentContainer{
    padding-top: 38px;
  }

  .titleContainer{
    text-align: center;
    font-size: 28px;
    height:auto;
    width: 162px;
  }

.titleText1{
  width: 100%;
  line-height: 31.07px;
}

.titleText2{
  font-size: 54.15px;
  width: 100%;
  line-height: 65.81px;
}
  
  .bgImage{
    max-width: 100%;
    max-height: 268px;
    transform: scale(1);
    margin-top: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .section1FDesign{
    top: 16rem;
  }
  
    .bodyContainer{
      margin: 0rem;
      width: 100%;
      gap:0rem;
      margin-top: 0.85rem;
    }
  
    .textContainer{
      width: 235px;
      height: auto;
      font-size: 0.78rem;
      line-height: 18px;
    }
  
    .bodyText2{
      font-size: 0.85rem;
    }  
  }