@use '../../../styles/base/settings' as *;

$backgoundColor:#56DACC;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 5rem;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  height: 100%;
  width: 100%;
  background-color: $backgoundColor;
  border-radius: 5rem;
  position: relative;
  padding-top: 70px;
}

.titleText{
  text-align: start;
  color: #FFFFFF;
  font-size: 3.5rem;
  height:136px;
  width: 710px;
  line-height: 3.65rem;
  font-family: "Ubuntu-Regular";
  padding-left: 60px;
  font-weight: 700;
}

.arreVoiceMobileIllustration{
    position: absolute;
    top:40px;
    right: 68px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('https://asset.arredigital.co/Arr%C3%A9+Voice/images/arreVoiceAppImg.png');
    width: 296px;
    height: 600px;
}

.bodyContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap:90px;
  margin-top: 50px;
  padding-left: 60px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #FFFFFF;
  font-family: "Montserrat-Regular";
  width: 455px;
  height: 125px;
  font-size: 1.45rem;
  line-height: 1.5625rem;
}

.text1{
  font-size: 2.45rem;
  font-weight: 400;
}

.text1Color{
  color: #FF8431;
}

.socialIconsContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:1rem;
}

.socialIcon{
  width: 300.62px;
  height: 107px;
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
@media (max-width: $breakpoint-tablet) {
  .container {
    margin-top: 2.5rem;
  }

  .contentContainer{
    border-radius: 0px;
    padding-top: 0px;
    background-color: #FFFFFF;
  }

  .titleContainer{
    display: flex;
    justify-content: flex-start;
  }

  .titleText{
    text-align: start;
    color: #2EDBC3;
    font-size: 32px;
    height:375px;
    width: 100%;
    line-height: 33.89px;
    padding-left: 32px;
    padding-right: 10px;
    font-weight: 700;
    font-family: "Ubuntu-Medium";
    
  }

  .arreVoiceMobileIllustration{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    right: 0rem;
    overflow: hidden;
    width: 266px;
    height: 540px;
    margin-top: 3.5rem;
  }

  .bodyContainer{
    background-color: #000000;
    justify-content: flex-start;
    gap:35px;
    margin-top: 0rem;
    padding-top: 17.5rem;
    padding-left: 0px;
    height:auto;
    padding-bottom: 10px;
  }

  .textContainer{
    width: 100%;
    height: auto;
    font-size: 12px;
    line-height: 19.85px;
    font-weight: 400;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 44px;
  }
  
  .text1{
    font-size: 18px;
    font-weight: 500;
    line-height: 24.28px;
  }
  
  .text1Color{
    color: #FF8431;
  }

  .socialIconsContainer{
    display: flex;
    justify-content: center;
    gap:16px;
    width: 100%;
  }
  
  .socialIcon{
    width: 145px;
    height: 48px;
  }
}


//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .container {
    margin-top: 40px;
  }
  
  .contentContainer{
    background-color: $backgoundColor;
    border-radius: 48px;
    padding-top: 43px;
  }
  
  .titleText{
    color: #FFFFFF;
    font-size: 40px;
    height:auto;
    width: 365px;
    line-height: 42.36px;
    font-family: "Ubuntu-Regular";
    padding: 0px;
    margin-left: 30px;
  }

  .arreVoiceMobileIllustration{
    position: absolute;
    top:40px;
    right: 35px;
    overflow: hidden;
    width: 218px;
    height: 440px;
  }
  
  .bodyContainer{
    background-color: transparent;
    display: flex;
    height:auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap:90px;
    margin-top: 39px;
    padding: 0px;
    margin-left: 30px;
  }
  
  .textContainer{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    padding: 0px;
    width: 377px;
    height: auto;
    font-size: 18px;
    line-height: 21.94px;
    gap: 1rem;
  }
  
  .text1{
    font-size: 30px;
    font-weight: 400;
    line-height: 36.57px;
  }
  
  .text1Color{
    color: #FF8431;
  }
  
  .socialIconsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:20.44px;
  }
  
  .socialIcon{
    width: 179px;
    height: 63px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) {
  .contentContainer{
    padding-top: 70px;
  }
  
  .titleText{
    font-size: 3rem;
    height:auto;
    width: 510px;
    line-height: 3.45rem;
  }

  .arreVoiceMobileIllustration{
    position: absolute;
    top:40px;
    right: 50px;
    overflow: hidden;
    width: 285px;
    height: 550px;
  }
  
  //
  .bodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap:90px;
    margin-top: 50px;
    padding-left: 60px;
  }
  
  .textContainer{
    width: 415px;
    height: 125px;
    font-size: 1.35rem;
    line-height: 1.5625rem;
  }
  
  .text1{
    font-size: 2.35rem;
    font-weight: 400;
  }
  
  .socialIconsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:1rem;
  }
  
  .socialIcon{
    width: 295.62px;
    height: 102px;
  }
 }

//social icons
//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1200px) {
  .socialIcon{
    width: 215.62px;
    height: 75px;
  }
 }

 //small mobile :: < 360px
 @media (max-width: 360px) {
  .titleText{
    font-size: 24px;
    line-height: 28.77px;
    padding: 0px 15px;
    max-width:  100%;
    height:265px;
  }
  .titleContainer{
    padding-bottom: 2.8rem;
  }
  .arreVoiceMobileIllustration{
    margin-top: 2.5rem;
    width: 230px;
    height: 465px;
  }

  .bodyContainer{
    padding-top: 15.95rem;
    gap:25px;
  }

  .textContainer{
    padding: 0px 10px;
  }
}