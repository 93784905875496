@use '../../styles/base/settings' as *;

//page content
.container {
  height: 100vh;
  width:100%;
  overflow-x: hidden;
}
.container::-webkit-scrollbar {
  display: none;
}
//mobile
.headerContainer {
  position: sticky;
  top:0rem;
  z-index: 2;
  transition: top 0.5s;
}
.section1Container,.section3Container{
 width: 100%;
}
.section2Container{
  width: 100%;
  background: var(--Gradient-2, linear-gradient(134deg, #FFB800 -13.99%, #F15701 110.79%));
}
.footerContainer{
  width: 100%;
}
//tablet :: >= 768px
@media (min-width: $breakpoint-tablet){
  .footerContainer{
    margin-top: -40px;
  }
}
//desktop  :: >= 1440px
@media (min-width: $breakpoint-desktop) {
  .contentWrapper{
    // max-width: $containerWidth;
    align-content: center;
    margin: auto;
  }
}