@use '../../../styles/base/settings' as *;

//mobile
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 10px 25px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 15px rgba(0, 0, 0, 0.1);
}
.headerArreLogo{
  width: 59px;
  height: 40px;
}
.headerSocialIconsContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.headerSocialIcon{
  width: 94px;
  height: 34px;
}
@media (min-width: 836px){
  .headerContainer {
    padding: 10px 30px;
  }
  .headerArreLogo{
    width: 100px;
    height: 69px;
  }
  .headerSocialIconsContainer{
    gap: 20px;
  }
  .headerSocialIcon{
    width: 174px;
    height: 64px;
  }
}
@media (min-width: 1280px){
  .headerContainer {
    padding: 10px 120px;
  }
}

//small mobile
@media (max-width: 320px){
  .headerArreLogo{
    width: 50px;
  }
  .headerSocialIcon{
    width: 78px;
  }
}
