@use '../../../styles/base/settings' as *;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 5rem;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #FFB800 0%, #FF782D 97.87%);
  border-radius: 5rem;
  position: relative;
  padding-top: 70px;
}

.titleText {
  float: right;
  color: #FFFFFF;
  font-size: 3.5rem;
  height:136px;
  width: 549px;
  line-height: 3.65rem;
  font-family: "Ubuntu-Regular";
  padding-right: 60px;
  font-weight: 500;
  letter-spacing: -3px;
  text-align: right;
}

.tagsContainer{
  position: absolute;
  top: 0rem;
  right: 0rem;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.tagsContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.tag{
  opacity: 13.5%;
  font-size: 3.25rem;
  font-weight: 800;
  position: absolute;
}

.tag1{top:1rem;right:5rem;}
.tag2{top:7rem;right:33rem;}
.tag3{top:19rem;right:12rem;}
.tag4{top:28rem;right:25.5rem;}
.tag5{top:31.5rem;right:8rem;}
.tag6{top:33rem;left:15rem;}

.interestsFDesignImg{
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 75%;
  object-fit: contain;
}

.starImg{
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5rem;
}

.lottiePlayer{
  position: absolute;
  top:-11.5rem;
  left: -19.2rem;
  overflow: hidden;
  width: 100%;
  height: 1023px;
  transform: scale(0.5);
}

.bodyContainer{
  position: absolute;
  right:60px;
  top: 255px;
  width: 435px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #FFFFFF;
  font-family: "Ubuntu-Light";
  font-style: normal;
  width: 100%;
  height: 175px;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  text-align: right;
}

.textBold{
  font-size: 1.4rem;
  font-family: "Ubuntu-Medium";
  font-weight: 800;
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
  @media (max-width: $breakpoint-tablet) {
  .container {
    margin-top: 2.5rem;
  }

  .contentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding-top: 0px;
    background-image: linear-gradient(180deg, #FFB800 0%, #F15701 92.17%);
  }

  .titleContainer{
    display: flex;
    justify-content: flex-start;
  }

  .titleText{
    float: none;
    font-size: 32px;
    height:auto;
    width: 100%;
    line-height: 33.89px;
    letter-spacing: -3px;
    text-align: start;
    padding: 0px 32px;
  }

  .tag{
    font-size: 1.85rem;
  }
  
  .tag1{top:6.5rem;right:1rem;}
  .tag2{top:9rem;left:2rem;}
  .tag3{top:14rem;right:2rem;}
  .tag4{top:19rem;left:1rem;}
  .tag5{top:21rem;right:2rem;}
  .tag6{top:33.2rem;left:2.9rem;}

  .interestsFDesignImg{
    display: none;
  }

  .starImg{
    display: none;
  }

  .lottiePlayer{
    position: static;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 261px;
    height: 261px;
    transform: scale(0.95);
    margin:0rem auto; 
    margin-top: -1.25rem;
  }

  .bodyContainer{
    position: static;
    gap:29px;
    margin-top: 0rem;
    padding: 0px 29px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
  }

  .textContainer{
    gap:1rem;
    width: 100%;
    height: auto;
    font-size: 16px;
    text-align: start;
    line-height: 18.38px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .container {
    margin-top: 40px;
  }

  .contentContainer{
    background-image: linear-gradient(180deg, #FFB800 0%, #FF782D 97.87%);
    position: relative;
    display: block;
    border-radius: 48px;
    padding-top: 60px;
  }

  .titleContainer{
    display: block;
  }

  .titleText {
    float: right;
    font-size: 40px;
    height:auto;
    width: 362px;
    line-height: 42.36px;
    letter-spacing: -1.6px;
    font-weight: 500;
    text-align: right;
    padding: 0px;
    margin-right: 46px;
  }

  .tag{
    font-size: 2.45rem;
  }
  
  .tag1{top:8.5rem;right:8rem;}
  .tag2{top:12rem;left:3rem;}
  .tag3{top:20rem;right:6rem;}
  .tag4{top:29rem;left:2rem;}
  .tag5{top:31rem;right:5.5rem;}
  .tag6{top:44rem;right:0.9rem;}

  .interestsFDesignImg{
    display: block;
    bottom: 0rem;
    right: 0rem;
  }
  
  .starImg{
    display: block;
    max-width: 125px;
  }

  .lottiePlayer{
    position: absolute;
    top: 65px;
    left: 18px;
    width: 334px;
    height: auto;
    transform: scale(0.95);
    margin:0rem auto; 
    margin-top: 0rem;
  }
  
  .bodyContainer{
    position: absolute;
    right:42px;
    top: 204px;
    width: 235px;
    padding: 0px;
    height: auto;
    align-items: center;
    justify-content: flex-start;
  }
  
  .textContainer{
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 18.7px;
    text-align: right;
    gap:1rem;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) { 
  .contentContainer{
    padding-top: 90px;
  }

  .titleText {
    // float: right;
    // color: #FFFFFF;
    font-size: 3rem;
    height:auto;
    width: 475px;
    line-height: 3.25rem;
    // font-family: "Ubuntu-Regular";
    // padding-right: 60px;
    // font-weight: 500;
    // letter-spacing: -3px;
    // text-align: right;
  }
  
  .tag1{top:2rem;right:5rem;}
  .tag2{top:7rem;right:33rem;}
  .tag3{top:19rem;right:12rem;}
  .tag4{top:28rem;right:25.5rem;}
  .tag5{top:31.5rem;right:8rem;}
  .tag6{top:33rem;left:15rem;}
  
  .lottiePlayer{
    position: absolute;
    top:-14.5rem;
    left: -14.2rem;
    overflow: hidden;
    width: 100%;
    height: 1023px;
    transform: scale(0.45);
  }

  .starImg{
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    width: 20%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5rem;
  }
  
  .bodyContainer{
    width: 375px;
  }
  
  .textContainer{
    height: auto;
    font-size: 1.09rem;
    line-height: 1.5625rem;
  }
  
  .textBold{
    font-size: 1.4rem;
    font-family: "Ubuntu-Medium";
    font-weight: 800;
  }
}

 //small mobile :: < 360px
 @media (max-width: 360px) {
  .titleContainer{
    display: flex;
    justify-content: center;
  }

  .titleText{
    font-size: 28px;
    line-height: 29.77px;
    padding: 0px 18px;
    max-width:  265px;
    letter-spacing: -2px;
  }

  .lottiePlayer{
    width: 215px;
    height: 215px;
  }

  .bodyContainer{
    padding: 0px 18px;
   }

  .textContainer{
    font-size: 14px;
    line-height: 17.28px;
  }

  .textBold{
    font-size: 16px;
    line-height: 19.28px;
  }
}

//tablet :: < 300px
@media (max-width: 300px) {
  .titleText{
    font-size: 24px;
    line-height: 28.77px;
    padding: 0px 12px;
    max-width:  225px;
  }
}