@use '../../../styles/base/settings' as *;

//mobile
.playStoreBtnsContainer{
  @include flexRowDivCenter;
  gap:15px;
  width: auto;
}
@mixin bgSocialImage {
  background-repeat: no-repeat;
  background-size: contain;
  width: 140px;
  height: 52px;
}
.playStoreBtnImage{
  background-image: url('../../../../public/assets/logos/playStoreBtnSm.svg');
  @include bgSocialImage;
}
.appStoreBtnImage{
  background-image: url('../../../../public/assets/logos/appStoreBtnSm.svg');
  @include bgSocialImage;
}

//tablet :: >= 768px
@media (min-width: $breakpoint-tablet){
  .playStoreBtnsContainer{
    gap:18px;
  }
  @mixin bgSocialImage {
    width: 162px;
    height: 60px;
  }
  .playStoreBtnImage{
    @include bgSocialImage;
  }
  .appStoreBtnImage{
    @include bgSocialImage;
  }
}

//desktop  :: >= 1440px
@media (min-width: $breakpoint-desktop) {
  .playStoreBtnsContainer{
    gap: 22px;
  }
  @mixin bgSocialImage {
    width: 198px;
    height: 72px;
  }
  .playStoreBtnImage{
    @include bgSocialImage;
  }
  .appStoreBtnImage{
    @include bgSocialImage;
  }
}

//small mobile  :: <325px
@media (max-width: 325px) {
  .playStoreBtnsContainer{
    gap:12px;
  }
  @mixin bgSocialImage {
    width: 100px;
    height: 38px;
  }
  .playStoreBtnImage{
    @include bgSocialImage;
  }
  .appStoreBtnImage{
    @include bgSocialImage;
  }
}