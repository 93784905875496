@use '../../styles/base/settings' as *;

//desktop
.homeContainer {
  height: 100vh;
  width:100%;
}

.homeContainer::-webkit-scrollbar {
  display: none;
}

.headerContainer {
  position: sticky;
  top:0rem;
  z-index: 2;
  transition: top 0.5s;
}

.mobileLogo{
  display: none;
}

.desktopLogo{
  display: block;
}

.sectionContainer{
  max-width: $containerWidth;
  padding: 0rem 5rem;
  height: $sectionHeight;
  width: 100%;
  margin: auto;
}

.section1Container{
  max-width: $containerWidth;
  padding: 0rem 0rem;
  height: 655px;
  width: 100%;
  margin: auto;
}

.section1Footer{
  display: none;
}

.section2Container{
  height: 695px;
}

.section3NewContainer{
  max-width: $containerWidth;
  width: 100%;
  margin: auto;
  height: auto;
}

.section6Container{
  max-width: $containerWidth;
  padding: 0rem 5rem;
  height: $sectionHeight;
  width: 100%;
  margin: auto;
}

.footerContainer{
  background-color: #11302C;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5rem;
}

.footerContent{
  max-width: $containerWidth;
  width: 100%;
  color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 125px;
}

.privacyContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.footerDivider{
  width: 2px;
  height: 34px;
  background: #FFFFFF;
  border-radius: 5px;
}

.footerSocialIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.footerSocialIcon{
  cursor: pointer;
  width: 29.64px;
  height: 24.08px;
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
@media (max-width: $breakpoint-tablet) {

  .mobileLogo{
    display: block;
  }
  
  .desktopLogo{
    display: none;
  }

  .sectionContainer{
    height: 594px;
    width: 100%;
    padding: 0rem 21px;
  }

  .section1Container{
    height: 640px;
    width: 100%;
    padding: 0rem;
  }

  .section1Footer{
    display: block;
    width: 100%;
  }

  .section3Container{
    height: 665px;
  }

  .section4Container{
    height: 568px;
  }

  .section6Container{
    height: auto;
    width: 100%;
    padding: 0rem;
  }

  .footerContainer{
    background-color: #000000;
    height: 110px;
    margin-top: -5px;
    z-index: 1;
    position: relative;
  }
  
  .footerContent{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
  
  .privacyContent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .footerDivider{
    display: none;
  }
  
  .footerSocialIcons{
    gap: 37px;
  }
  
  .footerSocialIcon{
    width: 23.33px;
    height: 23.33px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .mobileLogo{
    display: block;
  }
  
  .desktopLogo{
    display: none;
  }

  .sectionContainer{
    height: 500px;
    width: 100%;
    padding: 0rem 30px;
  }

  .section1Container{
    height: 638px;
    width: 100%;
    padding: 0rem;
  }

  .section1Footer{
    display: block;
    width: 100%;
  }

   // .section3Container{
  //   height: 665px;
  // }

  // .section3Container{
  //   height: 665px;
  // }

  // .section4Container{
  //   height: 568px;
  // }

  .section6Container{
    height: 500px;
    width: 100%;
    padding: 0rem 30px;
  }

  .footerContainer{
    background-color: #11302C;
    height: 95px;
    margin-top: 40px;
    position: static;
  }
  
  .footerContent{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 65px;
    padding-top: 0px;
  }
  
  .privacyContent{
    gap: 35px;
  }
  
  .footerDivider{
    display: block;
  }
  
  .footerSocialIcons{
    gap: 35px;
  }
  
  .footerSocialIcon{
    width: 29.64px;
    height: 24.08px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) {
  .section2Container{
    height: 595px;
  }
  .section3Container{
    height: 595px;
  }
  .section5Container{
    height: 585px;
  }
  .section6Container{
    height: 625px;
  }
}
//
@media (max-width: 360px){
  .sectionContainer{
    padding: 0px 12px;
  }
  .section1Container{
    height: 498px;
  }
  .section2Container{
    height: 555px;
  }

  .section4Container{
    height: 525px;
  }

  .section5Container{
    height: 505px;
  }

  .section6Container{
    height: auto;
    width: 100%;
    padding: 0rem;
  }

  .footerContent{
    gap: 26px;
  }
  
  .privacyContent{
    font-size: 12px;
    gap: 10px;
  }
  
  .footerSocialIcons{
    gap: 25px;
  }
}

//
@media (max-width: 310px){
  .section2Container{
    height: 525px;
  }
}

//
@media (max-width: 275px){
  .section2Container{
    height: 495px;
  }
}

@media (min-width: 400px) and (max-width: 545px) {
  .section2Container{
    height: 725px;
  }
}

//
@media (min-width: 545px) and (max-width: 605px) {
  .section2Container{
    height: 685px;
  }
}