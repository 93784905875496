@use '../../../styles/base/settings' as *;

//mobile
.container{
  margin-top: 28px;
  width: 100%;
}
.contentContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
}
.textContainer{
  display: flex;
  max-width: 420px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.text1{
  @include primaryFont;
  text-align: center;
  font-size: 16px;
}
.subtext1{
  @include primaryFont;
  color: #EE8C34;
  font-weight: 700;
}
@mixin primaryText{
  @include primaryFont;
  color: #808080;
  font-size: 16px;
  letter-spacing: -0.32px;
}
.text2{
  margin-top: 25px;
  @include primaryText;
}
.text3{
  margin-top: 20px;
  margin-bottom: 15px;
  @include primaryText;
}
.illustrationsContainer{
  @include flexColStartCenter;
  margin-top: 14px;
}
.illustrationContainer{
  @include flexRowDivCenter;
  width: 100%;
}
@mixin illustrationImgProps {
  background-repeat: no-repeat;
  background-size: contain;
}
.illustrationImg1{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s1_illustrationImg1.png');
  @include illustrationImgProps;
  width: 300px;
  height: 250px;
  margin-left: -65px;
}
.illText1{
  width: 140px;
  padding-right: 10px;
  margin-left: -50px;
  @include primaryFont;
  text-align: start;
  letter-spacing: -0.32px;
}
.illustrationContainer2{
  margin-top: -20px;
  @include flexRowDivCenter;
  flex-direction: row-reverse;
  width: 100%;
}
.illustrationImg2{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s1_illustrationImg2.png');
  @include illustrationImgProps;
  width: 350px;
  height: 245px;
  margin-left: -85px;
}
.illText2{
  width: 140px;
  padding-left: 10px;
  margin-left: 85px;
  @include primaryFont;
  color: #FB9A00;
  text-align: start;
  letter-spacing: -0.32px;
}
.illustrationImg3{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s1_illustrationImg3.png');
  @include illustrationImgProps;
  width: 285px;
  height: 255px;
  margin-left: -85px;
}
.illText3{
  width: 140px;
  padding-right: 10px;
  margin-left: -10px;
  @include primaryFont;
  text-align: start;
  letter-spacing: -0.32px;
}

//tablet :: >=391px
@media (min-width: 391px){
  .container{
    margin-top: 30px;
  }
  .textContainer{
    max-width: 500px;
  }
  .text1{
    font-size: 18px;
  }
  .subtext1{
    font-size: 18px;
  }
  @mixin primaryText{
    font-size: 18px;
    letter-spacing: -0.36px;
  }
  .text2{
    margin-top: 25px;
    margin-bottom: 15px;
    @include primaryText;
  }
  .text3{
    margin-top: 20px;
    margin-bottom: 15px;
    @include primaryText;
  }
  .illustrationsContainer{
    width: 100%;
  }
  .illustrationContainer{
    @include flexRowDivCenter;
    width: 100%;
  }
  .illustrationImg1{
    width: 300px;
    height: 250px;
    margin-left: 0px;
  }
  .illText1{
    width: 219px;
    padding-right: 10px;
    margin-left: 0px;
    font-size: 22px;
    letter-spacing: -0.44px;
  }
  .illustrationContainer2{
    margin-top: -20px;
    @include flexRowDivCenter;
    flex-direction: row-reverse;
    width: 100%;
  }
  .illustrationImg2{
    width: 350px;
    height: 245px;
    margin-left: 0px;
  }
  .illText2{
    width: 190px;
    padding-left: 10px;
    margin-left: 40px;
    font-size: 22px;
    letter-spacing: -0.44px;
  }
  .illustrationImg3{
    width: 285px;
    height: 255px;
    margin-left: 0px;
  }
  .illText3{
    width: 209px;
    padding-right: 0px;
    margin-left: 0px;
    font-size: 22px;
    letter-spacing: -0.44px;
  }
}

//desktop  :: >=745px
@media (min-width: 745px) {
  .container{
    margin-top: 0px;
    width: 100%;
  }
  .contentContainer{
    width: 100%;
    display: flex;
    padding: 30px 135px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
  }
  .textContainer{
    max-width: 600px;
  }
  .text1{
    font-size: 20px;
  }
  .subtext1{
    font-size: 20px;
  }
  @mixin primaryText{
    font-size: 20px;
    letter-spacing: -0.4px;
  }
  .text2{
    margin-top: 0px;
    margin-bottom: 0px;
    @include primaryText;
  }
  .text3{
    margin-top: 0px;
    margin-bottom: 0px;
    @include primaryText;
  }
  .illustrationsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 48px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .illustrationContainer{
    display: flex;
    min-width: 350px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
  }
  .illText1{
    width: 236px;
    padding-right: 0px;
    margin-left: 0px;
    font-size: 24px;
    letter-spacing: -0.48px;
    text-align: center;
  }
  .illustrationContainer2{
    margin-top: 0px;
    display: flex;
    min-width: 350px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
  }
  .illustrationImg2{
    width: 350px;
    height: 245px;
    margin-left: -50px;
  }
  .illText2{
    width: 205px;
    padding-left: 0px;
    margin-left: 0px;
    font-size: 24px;
    letter-spacing: -0.48px;
    text-align: center;
    margin-top: 5px;
  }
  .illustrationImg3{
    width: 285px;
    height: 255px;
    margin-left: 0px;
  }
  .illText3{
    width: 305px;
    padding-right: 0px;
    margin-left: 0px;
    font-size: 24px;
    letter-spacing: -0.48px;
    text-align: center;
    margin-top: -10px;
  }
}

//small mobile  :: <325px
@media (max-width: 325px) {
  .illustrationImg1{
    width: 200px;
    height: 160px;
    margin-left: -45px;
  }
  .illustrationImg2{
    width: 250px;
    height: 155px;
    margin-left: -55px;
  }
  .illText2{
    margin-left: 85px;
  }
  .illustrationImg3{
    width: 225px;
    height: 175px;
    margin-left: -65px;
  }
  .illText3{
    padding-top: 15px;
    padding-right: 10px;
    margin-left: -50px;
  }
}