@use '../../styles/base/settings' as *;

//page content
.page {
  height: 100vh;
  width:100%;
  overflow-y: scroll;
}

.page::-webkit-scrollbar {
  display: none;
}

.headerContainer {
  position: sticky;
  top:0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: top 0.5s;
}

//mobile
.bodyContainer {
  width: 100%;
  // height: 100%;
  height: calc(100% - 56px);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.oopsImage{
  width: 142px;
  height: 52px;
}

.errorImage{
  width: 275px;
  height: 242px;
}

.errorImage500{
  width: 290px;
  height: 310px;
}

.errorMsg  {
  padding-top: 20px;
  color: var(--bg-light, #232C36);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Ubuntu-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.26px;
}

.footer{
  width: 100%;
}

@media (min-width: 836px){  
  .bodyContainer {
    height: calc(100% - 86px);
  }
  .oopsImage{
    width: 225px;
    height: 85px;
  }
  
  .errorImage{
    width: 435px;
    height: 392px;
  }

  .errorImage500{
    width: 395px;
    height: 395px;
  }
  
  .errorMsg  {
    font-size: 20px;
  }
}

@media (min-width: 1280px) {
  .errorMsg  {
    font-size: 24px;
    line-height: 36px;
  }
}
