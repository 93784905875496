@use '../../styles/base/settings' as *;

//page content
.container {
  height: 100vh;
  width:100%;
  overflow-x: hidden;
}

.container::-webkit-scrollbar {
  display: none;
}

//mobile
.headerContainer {
  position: sticky;
  top:0rem;
  z-index: 2;
  transition: top 0.5s;
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.welcomeMsg {
  margin-top: 28px;
  width: 323px;
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
}

.welcomeIllustration {
  background-image: url('../../../public/assets/images/welcomeImgSM.svg');
  background-repeat: no-repeat;
  margin: 16px 2px;
  width: 300px;
  height: 290px;
}

.contentContainer{
  @include columnDiv;
  gap: 16px;
  margin: 0px 16px;
}

.textContainer{
  @include columnDiv;
  gap: 8px;
}

.heading {
  font-family: 'Acumin-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #EE8C34;
}

.text {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(60, 60, 67, 0.85);
}

.bottomContainer{
  margin-top: 30px;
  @include columnDiv;
  align-items: center;
  gap: 16px;
  background-color: #000000;
  padding: 20px 2px;
}

.bottomText1{
  width: 305px;
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FF8431;
}

.bottomText2{
  font-family: 'Ubuntu-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}

.bottomText3{
  color: #4BC7B6;
}

.socialIconsContainer{
  display: flex;
  justify-content: center;
  gap:16px;
  width: 100%;
}

.socialIcon{
  width: 150px;
  height: 50px;
}

//tablet :: < 768px
@media (min-width: $breakpoint-tablet){
  .welcomeMsg {
    margin-top: 44px;
    width: 650px;
    font-size: 48px;
    line-height: 66px;
    letter-spacing: 0.03em;
  }
  
  .welcomeIllustration {
    background-image: url('../../../public/assets/images/welcomeImgMD.svg');
    margin: 37px 2px;
    width: 460px;
    height: 440px;
  }

  .contentContainer{
    gap: 40px;
    margin: 0px 128px;
  }
  
  .heading {
    font-size: 36px;
    line-height: 43px;
  }
  
  .text {
    font-size: 24px;
    line-height: 140%;
  }
  
  .bottomContainer{
    display: none;
  }
}

//desktop  :: >= 1440px
@media (min-width: $breakpoint-desktop) {
  .contentWrapper{
    max-width: $containerWidth;
    align-content: center;
    margin: auto;
  }

  .welcomeContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  .welcomeMsg {
    margin: auto 0px;
    width: 635px;
    font-size: 64px;
    line-height: 87px;
    letter-spacing: 0.03em;
    text-align: start;
  }
  
  .welcomeIllustration {
   background-image: url('../../../public/assets/images/welcomeImgLG.svg');
    margin: 37px 2px;
    width: 660px;
    height: 630px;
  }

  .contentContainer{
    gap: 60px;
    margin: 0px 174px;
  }
  
  .heading {
    font-size: 48px;
    line-height: 58px;
  }
  
  .text {
    font-size: 32px;
    line-height: 140%;
  }
  
  .bottomContainer{
    display: none;
  }
}

 //small mobile :: < 360px
 @media (max-width: 360px) {
  .welcomeMsg{
    width: auto;
  }
  .welcomeIllustration {
    margin: 16px 5px;
  }
}