@use '../../../styles/base/settings' as *;


//mobile
.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 2.5rem;
  padding: 0rem 21px;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:20px;
  border-radius: 20px;
  padding: 23px 32px;
  background: linear-gradient(180deg, #FFB800 0%, #F15701 92.17%);
}

.titleText{
  font-family: 'Ubuntu-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 105.9%;
  letter-spacing: -2px;
  color: #FFFFFF;
  text-align: center;
  }

.fDesignImg{
  display: none;
}

.runIllustration{
  background-image: url('../../../../public/assets/images/runIllustrationSM.svg');
  width:244px;
  height:241px;
  margin: auto;
}

.bodyContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  font-family: 'Ubuntu-Light';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}

.learnMoreContainer {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.learnMoreText {
  font-family: 'Ubuntu-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000;
}

.socialIconsContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@mixin bgSocialImage {
  background-repeat: no-repeat;
  background-size: contain;
  width: 95px;
  height: 35px;
}

.instaImg {
  background-image: url('../../../../public/assets/images/instaBtnSmall.svg');
  @include bgSocialImage;
}

.playStoreBtnImage{
  background-image: url('../../../../public/assets/logos/playStoreBtnSm.svg');
  @include bgSocialImage;
}

.appStoreBtnImage{
  background-image: url('../../../../public/assets/logos/appStoreBtnSm.svg');
  @include bgSocialImage;
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet){
  .container {
    padding: 0px 30px;
    margin-top: 40px;
  }
  
  .contentContainer{
    align-items: flex-end;
    gap:20px;
    border-radius: 48px;
    padding: 52px 64px;
    position: relative;
    background: linear-gradient(180deg, #FFB800 0%, #FF782D 97.87%);
    z-index: 0;
  }
  
  .titleText{
    width: 368px;
    font-size: 40px;
    letter-spacing: -1.59px;
    text-align: right;
    }
  
  .runIllustration{
    position: absolute;
    z-index: 0;
    left:35px;
    background-image: url('../../../../public/assets/images/runIllustrationMD.svg');
    width:354px;
    height:351px;
    margin: auto;
  }
  
  .bodyContainer{
    align-items: flex-end;
  }
  
  .textContainer{
    width: 303px;
    text-align: right;
    gap: 18px;
    font-size: 18px;
    line-height: 19px;
  }

  @mixin bgSocialImage {
    width: 156px;
    height: 58px;
  }

  .instaImg {
    background-image: url('../../../../public/assets/images/instaBtnLarge.svg');
    @include bgSocialImage;
  }

  .playStoreBtnImage{
    background-image: url('../../../../public/assets/logos/playStoreBtnLg.svg');
    @include bgSocialImage;
  }
  
  .appStoreBtnImage{
    background-image: url('../../../../public/assets/logos/appStoreBtnLg.svg');
    @include bgSocialImage;
  }
}

//laptop  :: > 1024px
@media (min-width: 1025px) {  
  .container {
    padding: 0px 80px;
    margin-top: 5rem;
  }
  
  .contentContainer{
    border-radius: 5rem;
    padding: 70px 60px;
  }
  
  .runIllustration{
    left:85px;
  }
}

//laptop  :: > 1279px
@media (min-width: 1279px) { 
  .titleText{
    width: 434px;
    font-size: 63px;
    line-height: 105.9%;
    text-align: right;
    letter-spacing: -3px;
  }

.fDesignImg{
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}
  
  .runIllustration{
    left: 55px;
    background-image: url('../../../../public/assets/images/runIllustrationLG.svg');
    width: 576px;
    height: 566px;
  }

  .bodyContainer{
    gap: 35px;
  }

  .textContainer{
    width: 480px;
    gap: 20px;
    font-size: 24px;
    line-height: 25px;
  }

  .text2{
    padding-left: 150px;
  }

  .learnMoreText{
    font-size: 24px;
    line-height: 28px;
  }

  @mixin bgSocialImage {
    width: 175px;
    height: 65px;
  }

  .instaImg {
    background-image: url('../../../../public/assets/images/instaBtnLarge.svg');
    @include bgSocialImage;
  }

  .playStoreBtnImage{
    background-image: url('../../../../public/assets/logos/playStoreBtnLg.svg');
    @include bgSocialImage;
  }
  
  .appStoreBtnImage{
    background-image: url('../../../../public/assets/logos/appStoreBtnLg.svg');
    @include bgSocialImage;
  }
}

//very small mobile :: < 360px
@media (max-width: 300px) {  
  .container {
    padding: 0rem 12px;
  }

  .contentContainer{
    padding: 20px 18px;
  }
  
  .titleText{
    font-size: 28px;
  }
  
  .runIllustration{
    width:185px;
    height:195px;
    background-size: contain;
  }
}

//small mobile :: < 360px
@media (max-width: 360px) { 
  .container {
    padding: 0rem 12px;
  }
}