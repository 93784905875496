@use '../../styles/base/settings' as *;

//page content
.container {
  height: 100vh;
  width:100%;
  overflow-x: hidden;
}

.container::-webkit-scrollbar {
  display: none;
}

//mobile
.headerContainer {
  position: sticky;
  top:0rem;
  z-index: 2;
  transition: top 0.5s;
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.welcomeMsg {
  margin-top: 30px;
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #EE8C34;
}

.welcomeMsg2{
  display: none;
}

.welcomeIllustration {  
  background-image: url('../../../public/assets/images/communityImgSM.svg');
  background-repeat: no-repeat;
  width: 340px;
  height: 200px;
  margin: auto;
}

.contentContainer{
  @include columnDiv;
  gap: 16px;
  margin: 0px 16px;
}

.textContainer{
  @include columnDiv;
  gap: 8px;
}

.overviewHeader {
  font-family: 'Manrope-SemiBold';
  margin-top: 25px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: start;
  letter-spacing: 0.03em;
  color: #EE8C34;
}

.heading {
  margin-top: 5px;
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 140%;
  text-align: start;
  letter-spacing: 0.03em;
  color: #EE8C34;
}

.text {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(60, 60, 67, 0.85);
}

.hyperLink{
  color: blue;
  font-weight: 500;
}

.hyperLink:hover{
  text-decoration: underline;
}

.sectionContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.arrow{
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.talkingGirlIllustration{
  background-image: url('../../../public/assets/images/talkingGirlImgSM.svg');
  background-repeat: no-repeat;
  width: 343px;
  height: 201px;
  margin: auto;
}

.safetyIllustration{
  background-image: url('../../../public/assets/images/safetyImgSM.svg');
  background-repeat: no-repeat;
  width: 343px;
  height: 201px;
  margin: auto;
}

.listOfSections{
  margin: 2px 29px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.sectionLink{
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #4BC7B6;
}

.sectionLink:hover{
  font-weight: 800;
  text-decoration-line: none;
  cursor: pointer;
}

.heading2 {
  font-family: 'Acumin-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #EE8C34;
}

.orderedLists{
  margin: 0px;
  margin-left:18px;
}

.text2{
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
}

//tablet :: >= 768px
@media (min-width: $breakpoint-tablet){
  .welcomeMsg {
    margin-top: 25px;
    font-size: 48px;
    line-height: 66px;
    letter-spacing: 0.03em;
  }

  .welcomeMsg1{
    display: none;
  }

  .welcomeMsg2{
    display: block;
    width: 605px;
  }
  
  .welcomeIllustration {
    background-image: url('../../../public/assets/images/communityImgMD.svg');
    width: 664px;
    height: 391px;
  }
  
  .contentContainer{
    gap: 30px;
    margin: 0px 65px;
  }
  
  .textContainer{
    gap: 15px;
  }
  
  .overviewHeader {
    margin-top: 45px;
    font-size: 36px;
    line-height: 49px;
  }
  
  .heading {
    margin-top: 5px;
    font-size: 32px;
  }
  
  .text {
    font-size: 24px;
  }

  .arrow{
    width: 48px;
    height: 48px;
  }

  .talkingGirlIllustration{
    background-image: url('../../../public/assets/images/talkingGirlImgMD.svg');
    width: 648px;
    height: 380px;
  }
  
  .safetyIllustration{
    background-image: url('../../../public/assets/images/safetyImgMD.svg');
    width: 648px;
    height: 380px;
  }

  .listOfSections{
    margin: 0px 32px;
  }
  
  .sectionLink{
    font-size: 24px;
  }

  .heading2 {
    font-size: 28px;
  }
  
  .orderedLists{
    margin: 0px;
    margin-left:32px;
  }
  
  .text2{
    font-size: 24px;
  }
}

//desktop  :: >= 1440px
@media (min-width: $breakpoint-desktop) {
  .contentWrapper{
    max-width: $containerWidth;
    align-content: center;
    margin: auto;
  }

  .welcomeContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    margin: 0px 60px;
    margin-top: 154px;
    margin-bottom: 120px;
  }

  .welcomeMsg1{
    display: block;
    width: 472px;
  }

  .welcomeMsg2{
    display: none;
  }

  .welcomeMsg {
    margin: auto 0px;
    font-size: 70px;
    line-height: 96px;
    text-align: start;
  }
  
  .welcomeIllustration {
    background-image: url('../../../public/assets/images/communityImgLG.svg');
    width: 729px;
    height: 425px;
    margin: 0px;
  }

  .contentContainer{
    gap: 30px;
    margin: 0px 175px;
  }
  
  .overviewHeader {
    margin-top: 0px;
    font-size: 48px;
    line-height: 58px;
  }
  
  .heading {
    margin-top: 5px;
    font-size: 32px;
  }
  
  .text {
    font-size: 28px;
  }

  .arrow{
    width: 60px;
    height: 60px;
  }

  .talkingGirlIllustration{
    background-image: url('../../../public/assets/images/talkingGirlImgLG.svg');
    width: 665px;
    height: 389px;
  }
  
  .safetyIllustration{
    background-image: url('../../../public/assets/images/safetyImgLG.svg');
    width: 665px;
    height: 389px;
  }

  .listOfSections{
    margin: 0px 45px;
  }
  
  .sectionLink{
    font-size: 28px;
  }

  .heading2 {
    font-size: 28px;
  }
  
  .orderedLists{
    margin: 0px;
    margin-left:35px;
  }
  
  .text2{
    font-size: 28px;
  }
}
