@use '../../../styles/base/settings' as *;

//mobile
.mainContainer{
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.titleContainer{
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include primaryFont;
}
.titleText1{
  font-size: 32px;
}
.titleText2{
  font-size: 16px;
}
.bodyContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.container1{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
@mixin card1BgProps{
  border-radius: 16px;
  border: 1px solid #D6740C;
  background: var(--Gradient-2, linear-gradient(134deg, #FFB800 -13.99%, #F15701 110.79%));
}
@mixin card2BgProps{
  border-radius: 16px;
  border: 1px solid #10837A;
  background: var(--Teal-Gradient, linear-gradient(133deg, #01BBAC -10.37%, #009B8E 109.76%));
}
.subContainer1{
  display: flex;
  max-width: 342px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-bottom: 0px;
  @include card1BgProps;
}
.subContainer1TextContainer,.subContainer2TextContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include primaryFont;
  color: #FFFFFF;
}
.subContainer1Text1,.subContainer2Text1{
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 28px */
}
.subContainer1Text2,.subContainer2Text2{
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@mixin ImgProps {
  background-repeat: no-repeat;
  background-size: contain;
}
.subContainer1Img{//TODO:: small mobile responsive width size change
  margin-top: -35px;
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_subContainer1Img.png');
  @include ImgProps;
  width: 274px;
  height: 235px;
  align-self: center
}
.subContainer2{
  display: flex;
  max-width: 342px;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0px;
  @include card2BgProps;
  padding-top: 0px;
}
.subContainer2Img{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_subContainer2Img.png');
  @include ImgProps;
  width: 275px;
  height: 198px;
  align-self: center;
}
.subContainer2TextContainer{
  margin-top: -30px;
}
.textContainer1{
  @include primaryFont;
  text-align: center;
  font-size: 20px;
}
.container2{
  margin-top: -35px;
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_container2Img.png');
  @include ImgProps;
  width: 408px;
  height: 448px; 
}
.textContainer2{
  margin-top: -82px;
  @include primaryFont;
  text-align: center;
  font-size: 16px;
}
.textContainer2SubText{
  background: var(--Gradient-2, linear-gradient(134deg, #FFB800 -13.99%, #F15701 110.79%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin dividerImgProps{
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 2px;
}
.divider1{
  background-image: url('../../../../public/assets/images/ce_s3_divider1.svg');
  @include dividerImgProps;
}
.container3{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 342px;
  min-width: 324px;
}
.subContainer3{
  position: relative;
  z-index: 0;
  @include card1BgProps;
  display: flex;
  padding: 24px 24px 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.subContainer3TextContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @include primaryFont;
  color: #FFFFFF;
}
.subContainer3Text1{
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 28px */
}
.subContainer3Points{
  margin-left: 20px;
  @include flexColStartStart;
  gap: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.subContainer3Text3{
  font-size: 16px;
  font-weight: 300;
}
.cont3bgIllustration{
  position: absolute;
  z-index: -1;
  bottom: 0px;
  left: 0px;
  background-image: url('../../../../public/assets/images/ce_s3_cont3bgIllustration.png');
  @include ImgProps;
  width: 300px;
  height: 300px;
}
.cont3BgStar{
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: 0px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgStar.png');
  @include ImgProps;
  width: 88px;
  height: 184px;
}
.subContainer4{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_cont3Illustration.png');
  @include ImgProps;
  width: 100%;
  height: 200px;
}
.divider2{
  background-image: url('../../../../public/assets/images/ce_s3_divider2.svg');
  @include dividerImgProps;
}
.container4{
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  gap: 16px;
  max-width: 342px;
  min-width: 324px;
}
.textContainer3{
  @include primaryFont;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 28px */
}
.subContainer5{
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  gap: 16px;
}
.cont4bgIllustration{
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_cont4Illustration.png');
  @include ImgProps;
  width: 100%;
  height: 200px;
}
.tipsContainer{
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  @include card2BgProps;
}
.pointContainer{
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #10837A;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}
.pointText{
  @include primaryFont;
  text-align: start;
  background: var(--Gradient-2, linear-gradient(134deg, #FFB800 -13.99%, #F15701 110.79%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container5{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  gap: 24px;
  max-width: 342px;
  min-width: 324px;
  overflow: hidden;
}
.subContainer6 {
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  padding: 24px 24px 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @include card1BgProps;
}
.subCont6BgIllustration{
  position: absolute;
  z-index: -1;
  bottom: -85px;
  right: -100px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgIll2.png');
  @include ImgProps;
  width: 284px;
  height: 250px;
}
.subCont6BgStar,.subCont7BgStar{
  position: absolute;
  z-index: -1;
  top: 90px;
  left: -10px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgStar.png');
  @include ImgProps;
  width: 49px;
  height: 102px;
}
.subContainer7{
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @include card2BgProps;
}
.subCont7BgIllustration{
  position: absolute;
  z-index: -1;
  bottom: -55px;
  left: -45px;
  background-image: url('https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/images/ce_s3_subCont7BgIllustration.png');
  @include ImgProps;
  width: 259px;
  height: 259px;
  transform: scale(0.68);
}
.subCont7BgStar{
  top: 55px;
  left: auto;
  right: 0px;
}
.bottomPlayStoreBtns{
  display: none;
}

//tablet :: >=391px 
@media (min-width: 391px){
  .mainContainer{
    padding: 48px 24px;
    gap: 48px;
  }
  .titleContainer{
    min-width: 400px;
    max-width: 500px;
  }
  .titleText1{
    font-size: 34px;
  }
  .titleText2{
    font-size: 18px;
  }
  .bodyContainer{
    gap: 48px;
  }
  .container1{
    gap: 48px;
  }
  .subContainer1{
    min-width: 400px;
    max-width: 500px;
    align-items: center;
  }
  .subContainer1Text1,.subContainer2Text1{
    font-size: 30px;
  }
  .subContainer1Text2,.subContainer2Text2{
    font-size: 18px;
  }
  .subContainer1Img{//TODO:: small mobile responsive width size change
    margin-top: -25px;
    width: 335px;
    height: 285px;
  }
  .subContainer2{
    min-width: 400px;
    max-width: 500px;
    align-items: center;
  }
  .subContainer2Img{
    width: 365px;
    height: 228px;
  }
  .subContainer2TextContainer{
    margin-top: 0px;
  }
  .textContainer1{
    font-size: 18px;
  }
  .container2{
    margin-top: -55px;
  }
  .textContainer2{
    margin-top: -102px;
    font-size: 18px;
  }
  .container3{
    gap: 48px;
    min-width: 400px;
    max-width: 500px;
  }
  .subContainer3{
    align-items: center;
  }
  .subContainer3Text1{
    font-size: 30px;
  }
  .subContainer3Points{
    font-size: 18px;
  }
  .subContainer3Text3{
    font-size: 18px;
  }
  .subContainer4{
    height: 292px;
  }
  .container4{ 
    gap: 24px;
    min-width: 400px;
    max-width: 500px;
  }
  .subContainer5{ 
    gap: 24px;
  }
  .cont4bgIllustration{
    height: 292px;
  }
  .tipsContainer{
    padding: 16px;
    gap: 16px;
  }
  .container5{
    gap: 48px;
    min-width: 400px;
    max-width: 500px;
  }
}

//for rest all devices :: >=745px 
@media (min-width: 745px){
  .mainContainer{
    padding: 64px 24px;
    gap: 48px;
  }
  .titleContainer{
    max-width: 600px;
  }
  .titleText1{
    font-size: 34px;
  }
  .titleText2{
    font-size: 18px;
  }
  .bodyContainer{
    gap: 48px;
  }
  .container1{
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    gap: 48px;
    flex-wrap: wrap;
  }
  .subContainer1{
    flex: 1 0 0;
  }
  .subContainer2{
    justify-content: space-between;
    flex: 1 0 0;
  }
  .subContainer2Img{
    width: 395px;
    height: 288px;
  }
  .textContainer2{
    margin-top: -92px;
    font-size: 18px;
  }
  .container3{
    max-width: none;
    min-width: none;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 48px;
    flex-wrap: wrap;
  }
  .subContainer3{
    min-width: 450px;
    max-width: 500px;
    padding: 24px 24px 32px 24px;
    gap: 24px;
    flex: 1 0 0;
  }
  .subContainer3Text1{
    font-size: 32px;
  }
  .subContainer3Points{
    margin-left: 24px;
    gap: 20px;
    font-size: 20px;
  }
  .subContainer3Text3{
    font-size: 20px;
  }
  .subContainer4{
    width: 500px;
    height: 292.398px;
    max-width: 500px;
  }
  .container4{
    max-width: none;
    min-width: none;
    align-items: center;
    gap: 32px;
  }
  .textContainer3{
    font-size: 32px;
  }
  .subContainer5{
    padding: 0px 71px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 48px;
    flex-wrap: wrap;
  }
  .cont4bgIllustration{
    width: 500px;
    height: 292.398px;
  }
  .tipsContainer{
    min-width: 450px;
    max-width: 500px;
    padding: 20px;
    gap: 20px;
    flex: 1 0 0;
  }
  .pointText{
    font-size: 18px;
  }
  .container5{
    max-width: none;
    min-width: none;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    gap: 48px;
    flex-wrap: wrap;
  }
  .subContainer6 {
    width: auto;
    min-width: 450px;
    max-width: 500px;
    padding: 24px 48px 32px 48px;
    flex: 1 0 0;
  }
  .subContainer7{ 
    width: auto;
    min-width: 450px;
    max-width: 500px;
    padding: 24px 48px 32px 48px;
    flex: 1 0 0;
  }
}

// devices >=391px && <=445px
@media (min-width: 391px) and (max-width: 444px) {
  .titleContainer{
    min-width: 345px;
  }
  .bodyContainer{
    width: 100%;
  }
  .container1{
    width: 100%;
  }
  .subContainer1{
    min-width: 325px;
  }
  .subContainer1Img{
    margin-top: -25px;
    width: 295px;
    height: 245px;
  }
  .subContainer2{
     min-width: 325px;
  }
  .subContainer2Img{
    width: 295px;
    height: 208px;
  }
  .container3{
    min-width: 325px;
  }
  .subContainer4{
    height: 292px;
  }
  .container4{ 
    min-width: 325px;
  }
  .container5{
    min-width: 325px;
  }
}

// devices <=374px (min: 275px)
@media (max-width: 374px) {
  //mobile
  .mainContainer{
    padding: 25px 10px;
    gap: 25px;
  }
  .titleText1{
    font-size: 32px;
  }
  .titleText2{
    font-size: 16px;
  }
  .bodyContainer{
    width: 100%;
  }
  .container1{
    width: 100%;
  }
  .subContainer1{
    max-width: none;
    width: 100%;
    padding: 14px;
    padding-bottom: 0px;
  }
  .subContainer1Img{
    margin-top: -28px;
    width: 225px;
    height: 185px;
    align-self: center;
  }
  .subContainer2{
    max-width: none;
    width: 100%;
    padding: 14px;
    padding-top: 0px;
  }
  .container3{
    min-width: 175px;
    max-width: 342px;
    width: 100%;
  }
  .subContainer3{
    padding: 12px;
  }
  .subContainer3Points{
    margin-left: 12px;
  }
  .cont3bgIllustration{
    width: 200px;
    height: 200px;
  }
  .cont3BgStar{
    width: 64px;
    height: 184px;
  }
  .subContainer4{
    width: 100%;
    height: 215px;
  }
  .container4{
    max-width: 342px;
    min-width: 175px;
  }
  .tipsContainer{
    padding: 10px;
    gap: 10px;
  }
  .pointContainer{
    padding: 12px;
    gap: 14px;
  }
  .container5{
    max-width: 342px;
    min-width: 175px;
  }
  .subContainer6 {
    padding: 16px;
  }
  .subContainer7{
    padding: 16px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .bottomPlayStoreBtns{
    display: block;
  }
}