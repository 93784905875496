@use '../../../styles/base/settings' as *;
//mobile
.footerContainer{
  background-color: #000000;
  height: 130px;
  margin-top: -5px;
  z-index: 1;
  position: relative;
}

.footerContent{
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.privacyContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.privacySection{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  color: #FFFFFF;
}

.footerDivider{
  display: none;
}

.footerSocialIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 37px;
}

.footerSocialIcon{
  width: 28px;
  height: 28px;
  cursor: pointer;
}

//tablet :: >=768px
@media (min-width: $breakpoint-tablet) {
  .footerContainer{
    background-color: #11302C;
    height: 105px;
    margin-top: 40px;
    position: static;
  }
  
  .footerContent{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 65px;
    padding: 20px 0px;
  }
  
  .privacyContent{
    gap: 35px;
  }

  .privacySection{
    gap: 8px;
  }
  
  .footerDivider{
    display: block;
  }
  
  .footerSocialIcons{
    gap: 35px;
  }
  
  .footerSocialIcon{
    width: 34px;
    height: 34px;
  }
}

//laptop  :: >=1024px
@media (min-width: $breakpoint-laptop){
  .footerContent{
    padding: 30px 0px;
  }
  
  .privacyContent{
    gap: 35px;
  }

  .privacySection{
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 35px;
  }
  
  .footerDivider{
    display: block;
  }
}
//
@media (max-width: 360px){
  .footerContainer{
    height: 115px;
  }
  
  .privacySection{
    font-size: 12px;
  }
}