@use '../../../styles/base/settings' as *;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 5rem;
}

.contentContainer{
  font-family: "Ubuntu-Medium";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(247.31deg, #62D1C2 -18.61%, #1DDDC3 52.16%, #039C87 115.55%);
  border-radius: 5rem;
  position: relative;
  padding-top: 70px;
}

.titleText{
  text-align: start;
  color: #FFFFFF;
  font-size: 3.5rem;
  height:128px;
  width: 521px;
  line-height: 3.65rem;
  font-family: "Ubuntu-Regular";
  padding-left: 60px;
  font-weight: 500;
  letter-spacing: -3px;
}

.tagsContainer{
  position: absolute;
  top: 0rem;
  right: 0rem;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.tagsContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.tag{
  opacity: 13.5%;
  font-size: 3.25rem;
  font-weight: 800;
  position: absolute;
}

.tag1{top:4rem;right:28rem;}
.tag2{bottom:7rem;right:29rem;}
.tag3{top:16.5rem;right:28rem;}
.tag4{bottom:2rem;left:32.5rem;}
.tag5{top:31.5rem;right:8rem;}
.tag6{bottom:10rem;left:12rem;}

.languageFDesignImg{
  position: absolute;
  top: 15rem;
  left: 0rem;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.backgroundImage{
  position: absolute;
  top:-1rem;
  right: 0rem;
  overflow: hidden;
  width: 100%;
  transform: scale(0.9);
  display: flex;
  justify-content: flex-end;
}

.bodyContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap:50px;
  margin-top: 150px;
  padding-left: 60px;
  width: 418px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #FFFFFF;
  font-family: "Ubuntu-Light";
  width: 100%;
  height: 125px;
  font-size: 1.35rem;
  line-height: 1.5625rem;
}

.textBold{
  font-size: 1.4rem;
  font-family: "Ubuntu-Medium";
  font-weight: 800;
}

// //phone :: < 480px
// @media (max-width: $breakpoint-phone) {
  //tablet :: < 768px
  @media (max-width: $breakpoint-tablet) {
  .container {
    margin-top: 2.5rem;
  }
  
  .contentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding-top: 0px;
    background-image: linear-gradient(180deg, #62D1C2 0%, #1DDDC3 47.95%, rgba(3, 156, 135, 0.86) 100%);
  }

  .titleContainer{
    display: flex;
    justify-content: flex-start;
  }

  .titleText{
    font-size: 30px;
    height:72px;
    width: 100%;
    line-height: 31.77px;
    letter-spacing: -2px;
    text-align: start;
    padding: 0px 32px;
  }

  .tagsContainer{
    display: block;
  }

  .tag{
    font-size: 2.25rem;
  }
  
  .tag1{top:6.5rem;left:1rem;}
  .tag2{top:9.5rem;right:1rem;}
  .tag3{top:14rem;left:0.5rem;}
  .tag4{top:19rem;right:1rem;}
  .tag5{top:21rem;left:1rem;}
  .tag6{top:25.5rem;right:2.9rem;}

  .languageFDesignImg{
    display: none;
  }

  .backgroundImage{
    position: static;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 136px;
    height: 209px;
    transform: scale(0.95);
    margin:0rem auto; 
    margin-top: 1.05rem;
  }

  .bodyContainer{
    gap:29px;
   margin-top: 2rem;
   padding: 0px 29px;
   width: 100%;
   height: auto;
   align-items: center;
   justify-content: flex-start;
  }

  .textContainer{
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 20.68px;
    gap: 1rem;
  }

  .textBold{
    font-family: "Ubuntu-Medium";
    font-size: 18px;
    line-height: 20.68px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .container {
    margin-top: 40px;
  }

  .contentContainer{
    background-image: linear-gradient(247.31deg, #62D1C2 -18.61%, #1DDDC3 52.16%, #039C87 115.55%);
    position: relative;
    display: block;
    border-radius: 48px;
    padding-top: 60px;
  }

 
  .titleText{
    font-size: 40px;
    height:auto;
    width: 381px;
    padding: 0px;
    margin-left: 30px;
    font-family: "Ubuntu-Regular";
    font-weight: 500;
    letter-spacing: -1.6px;
   line-height: 42.36px;
  }

  .tagsContainer{
    display: block;
  }

  .tag{
    font-size: 3.45rem;
  }
  
  .tag1{top:12.5rem;left:3rem;}
  .tag2{top:16.5rem;right:7rem;}
  .tag3{top:21rem;left:3rem;}
  .tag4{top:24rem;right:3rem;}
  .tag5{top:31rem;left:1rem;}
  .tag6{top:36.5rem;right:2.9rem;}

  .languageFDesignImg{
    display: block;
  }

  .backgroundImage{
    position: absolute;
    top:34px;
    right: 30px;
    overflow: hidden;
    width: 100%;
    transform: scale(0.9);
    display: flex;
    justify-content: flex-end;
    width: 280px;
    height: 431px;
    transform: scale(0.95);
    margin:0rem;
  }

  .bodyContainer{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap:50px;
  margin-top: 50px;
  margin-left: 30px;
  padding: 0px;
  width: 247px;
  height: auto;   
  }
  
  .textContainer{
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 18.7px;
    gap: 1rem;
  }
  
  .textBold{
    font-size: 18px;
    line-height: 18.7px;
  }
}

//tablet  :: >= 768px & < 1024px
@media (min-width: 1025px) and (max-width: 1279px) {
  .titleText{
    font-size: 2.65rem;
    height:auto;
    width: 421px;
    line-height: 3.25rem;
  }

  .tag1{top:4rem;right:28rem;}
  .tag2{bottom:4rem;right:22rem;}
  .tag3{top:16.5rem;right:28rem;}
  .tag4{bottom:2rem;left:32.5rem;}
  .tag5{top:33.5rem;right:2.2rem;}
  .tag6{bottom:10rem;left:12rem;}
  
  .languageFDesignImg{
    top: 15rem;
    left: 0rem;
  }
  
  .backgroundImage{
    right: -2rem;
    transform: scale(0.85);
  }
}

 //small mobile :: < 360px
 @media (max-width: 360px) {
  .titleContainer{
    display: flex;
    justify-content: center;
  }

  .titleText{
    font-size: 28px;
    line-height: 30.77px;
    padding: 0px 18px;
    max-width:  265px;
  }

  .bodyContainer{
    padding: 0px 18px;
   }

  .textContainer{
    font-size: 14px;
    line-height: 17.28px;
  }

  .textBold{
    font-size: 16px;
    line-height: 19.28px;
  }
}

//tablet :: < 300px
@media (max-width: 300px) {
  .titleText{
    font-size: 24px;
    line-height: 28.77px;
    padding: 0px 12px;
    max-width:  225px;
  }
}