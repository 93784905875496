@use '../../../styles/base/settings' as *;

//mobile
.mainContainer{
  margin-top: 40px;
  padding: 32px;
  width: 100%;
  // background: var(--Gradient-2, linear-gradient(134deg, #FFB800 -13.99%, #F15701 110.79%));
  position: relative;
  z-index: 1;
}
.contentContainer{
  @include flexColStartCenter;
  gap: 32px;
}
.container1{
  width: 100%;
  @include flexColStartStart;
  gap: 48px;
}
.arreVoiceLogoContainer{
  @include flexColStartStart;
  gap: 12px;
}
.arreVoiceLogo{
  width: 88px;
  height: 60px;
}
.text1{
  @include primaryFont;
  color: #FFFFFF;
}
.textContainer{
  @include flexColStartStart;
  gap: 12px;
}
.text2{
  @include primaryFont;
  text-align: start;
  color: #FFFFFF;
  font-weight: 100;
}
.text3{
  @include primaryFont;
  text-align: start;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 100%; /* 20px */
}
@mixin illustrationImgProps {
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  width: 284px;
  height: 250px;
}
.bgIllustration1{
  top: 0px;
  right: -90px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgIll1.png');
  @include illustrationImgProps;
}
.bgIllustration2{
  bottom: 0px;
  left: -40px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgIll2.png');
  @include illustrationImgProps;
}
.BgStar{
  top: 60px;
  right: 40px;
  background-image: url('../../../../public/assets/images/creatorEnglishSec2BgStar.png');
  @include illustrationImgProps;
  width: 50px;
  height: 102px;
}

//tablet :: >= 768px
@media (min-width: $breakpoint-tablet){
  .mainContainer{
    padding: 32px 48px;
  }
  .contentContainer{
    gap: 48px;
  }
  .container1{
    width: 450px;
  }
  .text1{
    font-size: 18px;
  }
  .text2{
    font-size: 18px;
  }
  .text3{
    font-size: 22px;
  }
  .bgIllustration1{
    top: 0px;
    right: 10px;
  }
  .bgIllustration2{
    bottom: 5px;
    left: 120px;
  }
  .BgStar{
    top: 50px;
    right: 150px;
  }
}

//desktop  :: >= 1440px
@media (min-width: $breakpoint-desktop) {
  .mainContainer{
    margin-top: 0px;
  }
  .container1{
    width: 600px;
  }
  .text1{
    font-size: 20px;
  }
  .text2{
    font-size: 20px;
  }
  .text3{
    font-size: 24px;
  }
  .bgIllustration1{
    top: 0px;
    right: 90px;
  }
  .bgIllustration2{
    bottom: 0px;
    left: 220px;
  }
  .BgStar{
    top: 50px;
    right: 420px;
  }
}

//small mobile  :: <325px
@media (max-width: 325px) {
  .mainContainer{
    padding: 20px;
  }
}