// import the required theme file with the correct color variables that are used in the baseTheme file
@use '../themes/_baseTheme.scss';
// Colors
$white: #ffffff;

// Break points
$breakpoint-phone: 480px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1440px;
$breakpoint-LargeDesktop: 1920px;

// Mixins
@mixin respond($breakpoint) {
  @if ($breakpoint == 'extraLarge') {
    @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-LargeDesktop) {
      @content;
    }
  }
  @if ($breakpoint == 'large') {
    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
      @content;
    }
  }
  @if ($breakpoint == 'medium') {
    @media (min-width: $breakpoint-phone) and (max-width: $breakpoint-tablet) {
      @content;
    }
  }
  @if ($breakpoint == 'small') {
    @media (max-width: $breakpoint-phone) {
      @content;
    }
  }
}

//desktop :: > =1440px
$containerWidth :1440px; 
$sectionHeight :665px; 

//phone :: < 480px
@media (min-width: $breakpoint-phone) {
}

//tablet  :: >= 768px & < 1024px
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
}

//laptop  :: >= 1024px & < 1440px
@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop) {
}

@mixin centerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin columnDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@mixin centerDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin flexColStartCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flexColStartStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flexRowDivCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

//fonts
@mixin primaryFont {
  color: #01BBAC;
  // text-align: center;
  font-family: 'Ubuntu-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
